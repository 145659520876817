<template>
  <div class="main">
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div id="dataList" slot="scrollList">
        <headSearch :search-out="headGo" />
        <!-- <div class="searchmain">
            <div class="searchdiv">
                <img src="../../assets/images/search.png" class="fangdajing">
                <input type="text" placeholder="搜索品牌优惠卷 优惠下单" class="searchinput">
            </div>
        </div> -->

        <!-- <div class="pxdiv">
            <ul class="pxul">
                <li :class="{pxworldselect:index==0}" @click="changeType(0)">
                    <div class="pxworld">优惠力度</div>
                </li>
                <li :class="{pxworldselect:index==1}" @click="changeType(1)">
                    <div class="pxworld">价格区间</div>
                    <div class="pxarrow">
                        <img src="../../assets/images/sxbottom.png" class="arrow">
                    </div>
                </li>
                <li :class="{pxworldselect:index==2}" @click="changeType(2)">
                    <div class="pxworld">佣金排序</div>
                    <div class="pxarrow">
                        <img src="../../assets/images/sxup.png" class="arrow">
                    </div>
                </li>
            </ul>
        </div> -->
        <div class="goodsList">
          <ul class="goodsul">
            <!-- <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="gaoyongFun"
                :immediate-check="false"
                > -->
            <li v-for="(item, jIndex) in list" :key="jIndex" @click="goDetail(item)">
              <div class="goodsleft">
                <imgLoading :src="item.path" class="goodsimg" />
                <!-- <img :src="item.path" class="goodsimg"> -->
              </div>
              <div class="goodsright">
                <div class="goods_name">{{ item.name }}</div>
                <div class="goodsyhjdiv">
                  <!--                            <div class="goodsyhj">{{item.coupon}}元优惠券</div>-->
                  <div class="goodspl">已出售{{ item.num }}件</div>
                </div>
                <!-- <div class="logodiv">
                    <img src="" class="logo" alt="">
                    <span class="compony">金河果品</span>
                </div> -->
                <div class="goodspricediv">
                  <div class="goodspicediv">
                    <div class="goodspricenow">¥{{ item.vip_price }}</div>
                    <div class="goodspriceold">¥{{ item.price }}</div>
                    <!-- <div class="goodsell">已出售1120件</div> -->
                  </div>
                </div>

              </div>
            </li>
            <!-- </van-list> -->

          </ul>
        </div>

        <!-- 筛选 -->
        <!-- <transition name="fade">
        <div v-show="showSx">
            <div class="grey" @click.prevent="closesx"></div>
            <div class="shuaixuandiv">
                <div class="sxmain">
                    <div style="height:200px">1</div>
                    <div style="height:50px">2</div>
                    <div style="height:50px">3</div>
                    <div style="height:50px">4</div>
                    <div style="height:50px">5</div>
                    <div style="height:50px">6</div>
                    <div style="height:50px">7</div>
                    <div style="height:50px">8</div>
                    <div style="height:50px">9</div>
                    <div style="height:50px">0</div>
                    <div style="height:50px">11</div>
                </div>
                <div class="bottombtn">
                    <div class="sxreset">重置</div>
                    <div class="sxenter" >确定</div>
                </div>
            </div>
        </div>
        </transition> -->
      </div>
    </mescroll>
  </div>
</template>
<script>
import headSearch from '@/component/head/search'
import imgLoading from '@/component/imgLoading'
import mescroll from '@/component/fresh_scroll/mescroll'
import { highShare } from '@/services/api'
import Vue from 'vue'
import { List, Tab, Tabs } from 'vant'

Vue.use(Tab).use(Tabs).use(List)
export default {
  name: 'HighCommission',
  components: {
    mescroll, imgLoading,
    headSearch
  },
  data() {
    return {
      headGo: {
        isShowSearch: false, // 输入框 title 控制
        isShowReturn: true, // 返回 上一页 显隐
        bgColor: '#fff', // 背景颜色
        titleData: document.title,
        callbackGo: this.callbackGo
      },
      mescrollDown: {
        use: true,
        bottom: '0'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 20 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      },
      showSx: false,
      list: [],
      index: 0, // 当前选择项
      loading: false,
      finished: false,
      page: 1,
      listnum: 0
    }
  },
  mounted() {
    // this.gaoyongFun();
  },
  methods: {
    callbackGo() {
      this.$router.go(-1)
    },
    mescrollInit(data) {
      this.mescroll = data
    },
    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // 接口请求数据
      this.gaoyongFun(
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr)
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    gaoyongFun(pageNum, pageSize, successCallback, errorCallback) {
      const param = {
        page: this.page,
        pageNum: 20
      }
      highShare(param).then((res) => {
        if (Number(res.code) === 200) {
          successCallback(res.data)
        }
      })
    },
    changeType(index) {
      this.index = index
    },
    // 跳转详情
    goDetail(data) {
      this.$router.push({ path: '/goodsDetailZY', query: { goodsId: data.product_id, skuid: data.product_sku_id }})
    }
  }
}
</script>
<style lang="less" scoped>
.main {
  background: #F6F6F6;
}

.searchmain {
  background: #ffffff;
  padding: 10px 14px;
}

.searchdiv {
  width: 350px;
  height: 34px;
  border-radius: 17px;
  background: #F0F2F5;
}

.fangdajing {
  float: left;
  width: 20px;
  height: 20px;
  margin-top: 7px;
  margin-left: 5px;
}

.searchinput {
  float: left;
  font-size: 13px;
  color: #999999;
  height: 34px;
  line-height: 34px;
  width: 300px;
  background: #F0F2F5;
}

.pxdiv {
  background: #ffffff;
  padding-top: 15px;
}

.pxul {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 10px;
  margin-bottom: 1px;
}

.pxul li {
  position: relative;
  width: 100%;
  font-size: 16px;
  color: #141414;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pxul li.pxworldselect {
  color: #FF001C;
}

.pxul li.pxworldselect::after {
  position: absolute;
  content: "";
  height: 2px;
  background: #FF001C;
  width: 100%;
  left: 0;
  bottom: -10px;
}

.pxarrow {
  width: 12px;
  height: 16px;
  margin-left: 2px;
}

.goodsList {
  margin-top: 10px;
  width: 375px;
}

.goodsul li {
  background: #ffffff;
  border-radius: 8px;
  padding: 14px;
  width: 375px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;
}

.goodsleft {
  width: 140px;
  height: 140px;
}

.goodsimg {
  width: 100%;
}

.goodsright {
  width: 195px;
}

.goods_name {
  color: #141414;
  font-size: 15px;
  line-height: 30px;
  font-weight: bold;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.goodsyhjdiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
}

.goodsyhj {
  background: url("../../assets/images/yhj.png") no-repeat;
  background-size: 82px 22px;
  width: 82px;
  height: 22px;
  text-align: center;
  line-height: 22px;
  font-size: 12px;
  color: #ED2E2E;
}

.goodspl {
  font-size: 12px;
  color: #999999;
  //margin-left: 5px;
}

.goodspricediv {
  //margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

.goodspicediv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.goodspricenow {
  color: #ED2E2E;
  font-weight: bold;
  font-size: 18px;
}

.goodspriceold {
  font-size: 12px;
  color: #999999;
  text-decoration: line-through;
  margin-left: 2px;
}

.goodsell {
  font-size: 12px;
  color: #999999;
  margin-left: 2px;
}

.logodiv {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.compony {
  font-size: 12px;
  color: #999999;
}

.logo {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
</style>
